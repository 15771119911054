<!-- START PRICING -->
<section class="section bg-light" id="pricing">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-12">
                <i class="ti-package title-icon text-muted"></i>
                <h3 class="title">Our <span class="fw-bold">Pricing</span></h3>
                <p class="text-muted mt-3 title-subtitle mx-auto">It is a long established fact that a reader will
                    be of a page when established fact looking at its layout.</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-4" *ngFor="let item of pricingData">
                <div class="bg-white price-box text-center mt-3" [ngClass]="{'active': item.isActive === true}">
                    <div class="plan-price fw-bold">
                        <h1 class="mb-0 fw-bold">{{item.price}}</h1>
                        <p class="mb-0">Per Month</p>
                    </div>
                    <div class="plan-features text-muted mt-5 mb-5">
                        <p>Bandwidth: <b class="text-primary">{{item.bandwidth}}</b></p>
                        <p>Onlinespace: {{item.space}}</p>
                        <p>Support: {{item.support}}</p>
                        <p>{{item.domain}} Domain</p>
                        <p class="mb-0">No Hidden Fees</p>
                    </div>
                    <div>
                        <a href="#" class="btn btn-primary btn-round">Join Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END PRICING -->