<!-- START TEAM -->
<section class="section" id="faq">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-12">
                <i class="ti-comments title-icon text-muted"></i>
                <h3 class="title">Our <span class="fw-bold">Support</span> Center</h3>
                <p class="text-muted mt-3 title-subtitle mx-auto">It is a long established fact that a reader will
                    be of a page when established fact looking at its layout.</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-6">
                <div class="pl-4 pr-4">
                    <div class="mt-4">
                        <div class="">
                            <h5 class="mb-0 f-18 font-weight-600">Q. What is Lorem Ipsum?</h5>
                        </div>
                        <div class="mt-3">
                            <ng-container [ngTemplateOutlet]="content"></ng-container>
                        </div>
                    </div>
                    <div class="mt-5">
                        <div class="">
                            <h5 class="mb-0 f-18 font-weight-600">Q. Why use Lorem Ipsum?</h5>
                        </div>
                        <div class="mt-3">
                            <ng-container [ngTemplateOutlet]="content"></ng-container>
                        </div>
                    </div>
                    <div class="mt-5">
                        <div class="">
                            <h5 class="mb-0 f-18 font-weight-600">Q. How many variations exist?</h5>
                        </div>
                        <div class="mt-3">
                            <ng-container [ngTemplateOutlet]="content"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="pl-4 pr-4">
                    <div class="mt-4">
                        <div class="">
                            <h5 class="mb-0 f-18 font-weight-600">Q. What is Lorem Ipsum?</h5>
                        </div>
                        <div class="mt-3">
                            <ng-container [ngTemplateOutlet]="content"></ng-container>
                        </div>
                    </div>
                    <div class="mt-5">
                        <div class="">
                            <h5 class="mb-0 f-18 font-weight-600">Q. Is safe use Lorem Ipsum?</h5>
                        </div>
                        <div class="mt-3">
                            <ng-container [ngTemplateOutlet]="content"></ng-container>
                        </div>
                    </div>
                    <div class="mt-5">
                        <div class="">
                            <h5 class="mb-0 f-18 font-weight-600">Q. When can be used?</h5>
                        </div>
                        <div class="mt-3">
                            <ng-container [ngTemplateOutlet]="content"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END TEAM -->

<!-- START CTA -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <h3 class="mb-4">You have another questions or need help?</h3>
                    <div class="">
                        <a href="#" class="btn btn-round btn-primary">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END TEAM -->

<ng-template #content>
    <p class="text-muted">You want customer to your store. Easily publish your coupans and
        when a user has manage to catch one of your coupens, the coupens wil be deducted
        from your coupens account at Clooger.</p>
</ng-template>