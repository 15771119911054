<!-- STRT FOOTER -->
<section class="section footer">
    <!-- <div class="bg-overlay"></div> -->
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <ul class="list-inline social mb-0">
                        <li class="list-inline-item"><a href="#" class="social-icon text-muted"><i
                                    class="ti-facebook"></i></a></li>
                        <li class="list-inline-item"><a href="#" class="social-icon text-muted"><i
                                    class="ti-twitter-alt"></i></a></li>
                        <li class="list-inline-item"><a href="#" class="social-icon text-muted"><i
                                    class="ti-linkedin"></i></a></li>
                    </ul>
                </div>
                <div class="footer-terms">
                    <ul class="mb-0 list-inline text-center mt-4 pt-2">
                        <li class="list-inline-item"><a href="#" class="text-muted">Terms & Condition</a></li>
                        <li class="list-inline-item"><a href="#" class="text-muted">Privacy Policy</a></li>
                        <li class="list-inline-item"><a href="#" class="text-muted">Contact Us</a></li>
                    </ul>
                </div>
                <div class="mt-4 pt-2 text-center">
                    <p class="copy-rights text-muted mb-0">
                        {{year}} © Grace Foundation Repair.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END FOOTER -->